<template>
  <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
    <li v-for="person in people" :key="person.email" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
      <div class="w-full flex items-center justify-between p-6 space-x-6">
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3">
            <h3 class="text-gray-900 text-sm font-medium truncate">{{ person.name }}</h3>
            <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{ person.number }}</span>
          </div>
          <p class="mt-2 text-gray-500 text-sm truncate text-left">{{ person.explain }}</p>
        </div>
        <component :is="person.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
      </div>
      <div>
        <!-- <div class="-mt-px flex divide-x divide-gray-200">
          <div class="w-0 flex-1 flex">
            <a :href="`mailto:${person.email}`" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <MailIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span class="ml-3">Email</span>
            </a>
          </div>
          <div class="-ml-px w-0 flex-1 flex">
            <a :href="`tel:${person.telephone}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <PhoneIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span class="ml-3">Call</span>
            </a>
          </div>
        </div> -->
      </div>
    </li>
  </ul>
</template>

<script>
// import { MailIcon, PhoneIcon } from '@heroicons/vue/solid'
import {
  OfficeBuildingIcon,
  UsersIcon
} from '@heroicons/vue/outline'

export default {
  name: 'report-stats',
  components: {
  },
  data: () => ({
    people: [
      {
        name: 'Users',
        number: '29',
        icon: UsersIcon,
        explain: 'Number of students'
      },
      {
        name: 'Institutes',
        number: '40',
        icon: OfficeBuildingIcon,
        explain: 'Number of Institutes'
      },
      {
        name: 'Payments',
        number: '20',
        icon: OfficeBuildingIcon,
        explain: 'Number of Institutes'
      },
      {
        name: 'Deposits',
        number: '28',
        icon: OfficeBuildingIcon,
        explain: 'Number of Institutes'
      }
    ]
  })
}
</script>
