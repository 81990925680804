<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-if="item" class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Desposit</h1>
        <p class="mt-2 text-sm text-gray-700">
          Verify a users deposit here after money reflects in the bank account.
          Once you do this, the user's wallet will be creditd.
        </p>
        <div class="sm:flex-auto mt-4">
          <p>Reference: {{ item.deposit_reference }}</p>
          <p>Expected Amount: {{ item.amount }}</p>
        </div>
      </div>
      <p class="bg-gray-700 p-3 text-white">
        Verified: {{ item.verified ? "Yes" : "No" }}
      </p>
    </div>
    <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
      <div class="space-y-6 sm:space-y-5">
        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Amount
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="name"
              v-model="verification.amount"
              id="name"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>

        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Deposit Reference
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="name"
              v-model="verification.deposit_reference"
              id="name"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>

        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Verification Reference
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="description"
              v-model="verification.verification_reference"
              id="description"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="mt-4">
      <button
        type="button"
        class="
          inline-flex
          items-center
          justify-center
          rounded-md
          border border-transparent
          bg-orange-600
          px-4
          py-2
          text-sm
          font-medium
          text-white
          shadow-sm
          hover:bg-orange-700
          focus:outline-none
          focus:ring-2
          focus:ring-orange-500
          focus:ring-offset-2
          sm:w-auto
        "
        @click="verify()"
      >
        Verify Payment
      </button>
    </div>
    <br />
    <hr />
    <br />
    <div class="sm:flex-auto mt-4">
      <p>Reference: {{ item.deposit_reference }}</p>
      <p>Expected Amount: {{ item.amount }}</p>
    </div>

    <div class="sm:flex-auto mt-4">
      <h2 class=" font-bold">Student</h2>
      <p>Name: {{ item.student_account.user.name }}</p>
      <p>Surname: {{ item.student_account.user.surname }}</p>
      <p>Email: {{ item.student_account.user.email }}</p>
      <p>Phone: {{ item.student_account.user.phone }}</p>
    </div>

    <div class="sm:flex-auto mt-4">
      <h2 class=" font-bold">Wallet</h2>
      <p>Amount: {{ item.wallet.amount}}</p>
      <p>Country: {{ item.wallet.country.currency_name}}</p>
      <p>Currency: {{ item.student_account.user.email }}</p>
    </div>

    <div class="sm:flex-auto mt-4">
      <h2 class=" font-bold">Bank</h2>
      <p>Name: {{ item.bank.name}}</p>
      <p>Branch: {{item.bank.branch_code}}</p>
      <p>Branch Code: {{ item.bank.branch_code }}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  created () {
    this.moment = moment
    this.getDeposit()
  },
  mounted () {
  },
  data: () => ({
    verification: {
      amount: '',
      deposit_reference: '',
      verification_reference: ''
    },
    item: {
      student_account: {
        user: {
          name: '',
          surname: '',
          email: '',
          phone: ''
        },
        wallet: {
          amount: 0,
          country: {
            currency_name: ''
          }
        }
      }
    }
  }),
  methods: {
    verify () {
      this.axios
        .post('api-dashboard/deposit/', this.verification)
        .then((res) => {
          this.$toast.show('Verification made', {
            position: 'top-right'
          })
          this.$router.push('items-list')
        })
    },
    getDeposit () {
      this.axios
        .get('api-dashboard/deposit/' + this.$route.params.id)
        .then((res) => {
          this.item = res.data
        })
    }
  }
}
</script>
