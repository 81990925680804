import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '../layouts/DashboardLayout.vue'
import Login from '../views/Auth/LoginView.vue'
import ReportStats from '../views/Dashboard/Reports/Stats.vue'
import UserList from '../views/Dashboard/Users/UsersList.vue'
import DespositList from '../views/Dashboard/Deposits/DepositsList.vue'
import DespositDetail from '../views/Dashboard/Deposits/DepositsDetail.vue'
import InstitutesList from '../views/Dashboard/Institutes/InstitutesList.vue'
import PaymentList from '../views/Dashboard/Payments/PaymentsList.vue'
import ConversionsList from '../views/Dashboard/Conversions/ConversionsList.vue'
import PageNotFound from '../views/PageNotFound.vue'
import NotAuthorised from '../views/NotAuthorised.vue'

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true
    },
    children: [
      {
        path: '/',
        name: 'report-stats',
        component: ReportStats
      },
      {
        path: 'users-list',
        name: 'users-list',
        component: UserList
      },
      {
        path: 'deposit-detail/:id',
        name: 'deposit-detail',
        component: DespositDetail
      },
      {
        path: 'deposits-list',
        name: 'deposits-list',
        component: DespositList
      },
      {
        path: 'payments-list',
        name: 'payments-list',
        component: PaymentList
      },
      {
        path: 'conversions-list',
        name: 'conversions-list',
        component: ConversionsList
      },
      {
        path: 'institutes-list',
        name: 'institutes-list',
        component: InstitutesList
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/not-authorised',
    name: 'not-authorised',
    component: NotAuthorised
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes
})

export default (app) => {
  app.router = router
  app.use(router)
}
