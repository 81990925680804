import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import http from './http'
import auth from './plugins/auth.js'
import router from './router'
import './index.css'
import Toaster from '@meforma/vue-toaster'

const app = createApp(App)
app.use(Toaster)
app.use(store)
app.use(http)
app.use(router)
app.use(auth)
app.mount('#app')
