<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Payments</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the payments in the system.
        </p>
      </div>
      <!-- <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-indigo-600
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-indigo-700
            focus:outline-none
            focus:ring-2
            focus:ring-indigo-500
            focus:ring-offset-2
            sm:w-auto
          "
        >
          Invite payment
        </button>
      </div> -->
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="
              overflow-hidden
              shadow
              ring-1 ring-black ring-opacity-5
              md:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      py-3.5
                      pl-4
                      pr-3
                      text-left text-sm
                      font-semibold
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Amount, Currency
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Processed
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    University
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Created On
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="payment in payments" :key="payment.id">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="h-10 w-10 flex-shrink-0">
                        <img
                          v-if="payment.student_account.user.avatar"
                          class="h-10 w-10 rounded-full"
                          :src="payment.student_account.user.avatar"
                          alt=""
                        />
                        <UserIcon
                          v-if="!payment.student_account.user.avatar"
                          class="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          {{
                            payment.student_account.user.name +
                            " " +
                            payment.student_account.user.surname
                          }}
                        </div>
                              <div class="text-gray-500">
                          <strong>Wallet: </strong> {{ payment.wallet.country.currency_code + ' ' + payment.wallet.amount }}
                        </div>
                        <div class="text-gray-400">
                          {{ payment.wallet.uuid }}
                        </div>
                        <div class="text-gray-500">{{ payment.email }}</div>
                        <div class="text-gray-500">{{ payment.phone }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span
                      :class="[
                        payment.verified
                          ? 'text-green-800 bg-green-100'
                          : 'text-red-800 bg-red-100',
                        'inline-flex rounded-full  px-2 text-xs font-semibold leading-5',
                      ]"
                      >{{ payment.amount }}</span
                    >
                    <div class="text-gray-500">
                      {{ payment.wallet.country.name }}
                    </div>
                    <div class="text-gray-500">
                      {{ payment.wallet.country.currency_code }}
                    </div>
                    <div class="text-gray-500">
                      {{ payment.wallet.country.currency_name }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{ payment.processed ? "Yes" : "No" }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-500">
                      {{ payment.institute.name }}
                    </div>
                    <div class="text-gray-500">
                      {{ payment.institute.country.name }}
                    </div>
                    <div class="text-gray-500">
                      Reference: {{ payment.reference }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      moment(payment.created_at).format(
                        "dddd, MMMM YYYY, h:mm:ss"
                      )
                    }}
                  </td>
                  <td
                    class="
                      relative
                      whitespace-nowrap
                      py-4
                      pl-3
                      pr-4
                      text-right text-sm
                      font-medium
                      sm:pr-6
                    "
                  >
                    <a href="#" class="text-indigo-600 hover:text-indigo-900"
                      >View<span class="sr-only">, {{ payment.name }}</span></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserIcon } from '@heroicons/vue/outline'
import moment from 'moment'
export default {
  created () {
    this.moment = moment
  },
  components: {
    UserIcon
  },
  mounted () {
    this.getdeposits()
  },
  data: () => ({
    payments: []
  }),
  methods: {
    getdeposits () {
      this.axios.get('api-dashboard/payment').then((res) => {
        this.payments = res.data.data
      })
    }
  }
}
</script>
